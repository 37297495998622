nav .gmas-linkab {
  color: rgb(130, 172, 205) !important; /* Set the text color to red with !important */
}

nav .gmas-link {
    color: rgb(177, 187, 192) !important; /* Set the text color to red with !important */
  }

  @media (max-width: 868px) {
    .nav, .li, ul {
      display: flex;
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center items horizontally */
      background-color: #333; /* Change background color if desired */
      padding: 10px; /* Add some spacing around items */
      .nav-links-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  
  
    ul {
      list-style: none;
      padding: 0;
      width: 100%; /* Make the list take up full width */
    }
  
    li {
      margin-bottom: 5px; /* Add space between list items */
    }
  }