.App {
  text-align: center;
}


/* For screen width up to 768px (typical phone screens) */
@media (max-width: 768px) {
  .score-card,
  .right-card,
  .page-content,
  .user-info
  .user-info-container {
    display: none;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.smaller-text {
  font-size: 14px;
}

.smallerr-text {
  font-size: 8px;
}

.small-text {
  font-size: 16px;
}


/* App.css */
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button {
  position: fixed; /* Set the element to be fixed */
  bottom: 0; /* Align it to the bottom */
  left: 0; /* Align it to the left */
  display: inline-flex; /* Use flexbox */
  justify-content: center;
  border: none;
  width: 100px;
  background-color: rgb(99, 134, 209);
  cursor: pointer;
}

.login-content {
  display: flex; /* Use flexbox */
  align-items: center; /* Vertically center content */
}

.logout-image {
  width: 20px; /* Adjust to your desired width */
  height: 20px; /* Adjust to your desired height */
  margin-right: 5px; /* Space between image and text */
}

.login-text {
  font-size: 14px; /* Adjust to your desired font size */
}

.logout-button {
  display: inline-flex; /* Use flexbox */
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.logout-content {
  display: flex; /* Use flexbox */
  align-items: center; /* Vertically center content */
}

.logout-text {
  font-size: 14px; /* Adjust to your desired font size */
}

.score-card {
  position: fixed;
  top: 90px;
  left: 20px;
  background-color: #333;
  padding: 10px;
  color: white;
  border-radius: 10px;
}

.right-card {
  position: fixed;
  top: 90px;
  right: 20px;
  background-color: #333;
  padding: 10px;
  color: white;
  border-radius: 10px;
}

.logout-container {
  position: fixed; /* Set the element to be fixed */
  bottom: 0; /* Align it to the bottom */
  left: 0; /* Align it to the left */
  display: inline-flex; /* Use flexbox */
  justify-content: center;
  border: none;
  background-color: rgb(99, 134, 209);
  cursor: pointer;
}

.increase-container {
  /* Position the logout button at the bottom of the page */
  position: fixed;
  top: 195px;
  left: 50px;
}

.user-info-container {
  position: fixed;
  bottom: 60px;
  left: 20px;
  background-color: #333;
  padding: 2px;
  height: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.badges {
  background-color: black; /* Set the background color to black */
  padding: 10px; /* Add some padding for spacing */
}

