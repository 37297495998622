/* Popup.css */

/* Style for the pop-up container */
.popup-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 9999; /* Set a high z-index to ensure the pop-up is displayed above other elements */
    width: 74%;
  }
  
  /* Style for the pop-up message */
  .popup-card p {
    font-size: 16px;
    margin: 0;
    padding: 0;
    color: black;
  }
  
  /* Style for the "Close" button */
  .popup-card button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-card button:hover {
    background-color: #0056b3;
  }
  