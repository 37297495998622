.entireT {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 868px) {
  .Test {
    margin: 0;
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
  background-color: #333;
  align-items: center;
  width: 70px;
  min-height: 130px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  }
}

.Test {
  margin: 0;
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
  background-color: #333;
  align-items: center;
  width: 60%;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
}
  
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
    
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .right-cardt {
    display: flex;
    background-color: #333;
    padding: 10px;
    color: white;
    border-radius: 10px;
  }

  @media (min-width: 768px) {
    .right-cardt {
      display: none;
    }
  }
  
  button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
  }
  
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  button:hover {
    background-color: #555e7d;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }