.transformations-tutorial {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .transformations-tutorial h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .transformations-tutorial h2 {
    font-size: 1.8rem;
    margin-top: 30px;
  }
  
  .transformations-tutorial p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .transformations-tutorial ul {
    text-align: left;
    margin-left: 40px;
  }
  
  .transformations-tutorial li {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  