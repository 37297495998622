.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }

  .social-media-icons {
    display: flex;
    justify-content: center; /* Center icons horizontally */
    gap: 20px; /* Adjust the gap between icons */
    margin-top: 10px; /* Adjust the margin as needed */
  }
  
  .social-media-icons a {
    color: #333; /* Adjust icon color */
    font-size: 24px; /* Adjust icon size */
    text-decoration: none;
  }

  .ldrbbutton {
    display: flex;
    justify-content: center;
    max-width: 30%;
    background-color: #333;
    color: white;
    padding: 10px;
    margin: 0;
  }
  
  .about-text {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #666;
  }
  
  .about-text:last-child {
    margin-bottom: 0;
  }
  
  .about-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .about-button:hover {
    background-color: #0056b3;
  }
  
  /* Add more styling as needed to achieve your desired creative design */
  