/* SlopeTutorial.css */
.AreaVolume-tutorial {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

.AreaVolume-tutorial {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .AreaVolume-tutorial h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .AreaVolume-tutorial p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .video-container {
    position: relative;
    width: 100%;
    max-width: 560px; /* You can adjust this value to your preference */
    margin: 0 auto;
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
  }
  
  