/* Leaderboard.css */

@media (min-width: 768px) {
  .page-contenttwo,
  .user-infotwo,
  .increase-containersmall,
  .score-cardsmall,
  .badgess
   {
    display: none;
  }
}

.badgess {
  background-color: black; /* Set the background color to black */
  padding: 10px; /* Add some padding for spacing */
  border-radius: 10px;
  max-width: 290px;
  color: white;
}

.leaderboard {
    text-align: center;
    margin: 20px auto;
  }
  
  .leaderboard-table {
    background-color: #333;
    padding: 10px; /* Add some padding for spacing */
    border-radius: 10px;
    color: white;
    width: 64%;
    margin: 0 auto;
    border: 1px solid #ccc;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #333;
  }
  
  .leaderboard-table th {
    background-color: #333;
  }
  
  .leaderboard-table img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  table.leaderboard-table .badges-column {
    background-color: black;
  }  

/* Styles for the input container */
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    margin-bottom: 20px;
  }
  
  /* Style for the input field */
  .input-field {
    width: 50%;
    max-width: 300px; /* Adjust the max width as needed */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  /* Style for the add button */
  .add-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 50%;
    max-width: 300px; /* Adjust the max width as needed */
  }
  
  .add-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .tpart {
    background-color: #333;
    color: white;
    max-width: 280px;
    border-radius: 10px;
    padding: 10px;
  }

  .user-infotwo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .badges {
    background-color: black; /* Set the background color to black */
    padding: 10px; /* Add some padding for spacing */
  }
  

  .score-cardsmall {
    display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background-color: #333;
  padding: 10px;
  color: white;
  border-radius: 10px;
  }

  .increase-containersmall {
    /* Position the logout button at the bottom of the page */
    display: flex;
  }