.wpage {
  margin: 0 auto;
  padding: 8px;
  background-color: rgb(110, 112, 113);
  border: 10px solid transparent;
  border-radius: 20px;
}

.epage {
  max-width: 800px;
  margin: 0 auto;
  border: 10px solid transparent;
  border-radius: 20px;
  padding: 6px;
  text-align: center;
  background-color: #b4b1be;
  font-family: 'MedievalFont', sans-serif; /* Replace 'MedievalFont' with an actual medieval-style font */
  content: '';
  overflow: hidden;
}

.aniexp {
  background-color: #b4b1be;
  border: 10px solid transparent;
  border-radius: 10px;
  animation: glowingLava 3s ease infinite;
  overflow: hidden;
}

@keyframes glowingLava {
  0% {
    border-color: #ff5500;
    box-shadow: 0 0 20px 5px #ff5500;
  }
  50% {
    border-color: #ffaa00;
    box-shadow: 0 0 40px 10px #ffaa00;
  }
  100% {
    border-color: #ff5500;
    box-shadow: 0 0 20px 5px #ff5500;
  }
}


/* Add your medieval font import here */
@import url('https://fonts.googleapis.com/css?family=MedievalFont&display=swap');


.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 15px;
    overflow: hidden;
  }
  
  .button-link {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    width: 140px;
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  .checkbox.checked {
    background-color: #55a630; /* Change to your desired color for checked state */
    border-color: #55a630;
  }
  
  /* Add your existing CSS styles */

/* Add your existing CSS styles */

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: black; /* Set background color to black */
    color: white; /* Set text color to white */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 80%; /* Adjust the max-width to your preference */
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  .bckbttn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }
  