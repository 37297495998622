.landing-page {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .hero {
    background-color: #dfdede;
    padding: 4rem 0;
  }
  
  .hero-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .hero-content button {
    padding: 1rem 2rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 4rem 0;
  }
  
  .feature {
    max-width: 300px;
  }
  
  .feature h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .feature p {
    font-size: 1.1rem;
  }

  .tutorial-button {
    position: relative;
    color: #fff;
    font-size: 1.2rem;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tutorial-button:hover {
    background-color: #555; /* Change to a lighter color on hover */
  }

  .slope-tutorial {
    background-color: #f3e7b1;
  }
  
  .probability-tutorial {
    background-color: #f3e7b1;
  }

  .functions-tutorial {
    background-color: #f3e7b1;
  }

  .statistics-tutorial {
    background-color: #f3e7b1;
  }

  .scientific-tutorial {
    background-color: #f3e7b1;
  }

  .ratios-tutorial {
    background-color: #f3e7b1;
  }

  .AreaVolume-tutorial {
    background-color: #f3e7b1;
  }

  .transformations-tutorial {
    background-color: #f3e7b1;
  }
  
  .grade {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px 0 4px 0;
  }
  
  .orange {
    background-color: #f49c48;
    color: #fff;
  }
  
  .red {
    background-color: #e74c3c;
    color: #fff;
  }

  .yellow {
    background-color: #beb61a;
    color: #fff;
  }
  
  .rainbow-text {
    background-image: linear-gradient(45deg, violet, indigo, blue, green);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: rainbow 3s linear infinite;
    font-size: 4rem;
  }
  
  
  .form-container {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjust as needed */
    margin: 20px auto; /* Center the form horizontally */
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .chart {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .chart h2 {
    margin-top: 0;
  }
  
  .chart p {
    margin-bottom: 20px;
  }
  
  .chart ul {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .chart li {
    margin-bottom: 10px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  

  .cardfbot.active {
    border: 2px solid blue;
  }

  .chart-container {
    border-collapse: collapse;
    width: 75%;
    font-family: Arial, sans-serif;
    margin: 0 auto;
  }
  
  .chart-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .header {
    font-weight: bold;
    background-color: #f2f2f2;
  }
  
  .chart-cell {
    flex: 1;
    padding: 10px;
    text-align: center;
  }
  
  .chart-cell:first-child {
    text-align: left;
  }

  .chart-heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .main-heading {
    text-align: center;
    font-size: 36px; /* Adjust the font size as needed */
    font-weight: bold;
    margin-bottom: 10px; /* Add spacing between the heading and the chart-heading */
  }

  /* Styling for the card container */
.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 800px;
}

/* Styling for each card */
.card {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 250px;
  max-width: 400px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.card-grade {
  font-size: 14px;
  color: #666;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
}

.overlay-text {
  display: flex;
  transform: translate(-1%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  z-index: 1;
}

.overlay-texttwo {
  display: flex;
  transform: translate(-1%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  z-index: 1;
}

.card-bg {
  background-size: cover;
}

.fixed-card-height {
  height: 200px; /* Set the desired fixed height for the cards */
}

.badgemore {
  width: 90%;
}

.navbar {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  margin: 0;
}

.card-containerfbot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  padding: 8px;
  max-width: 50%;
}

.cardfbot {
  background-color: #ffffff;
  flex: 1;
  width: 180px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  transition: border-color 0.3s, transform 0.3s;
}

.cardfbot:hover {
  border-color: #007bff;
  transform: scale(1.05);
}




.navbar-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

body {
  margin: 0;
  padding: 0;
}

.navbar-link:hover {
  text-decoration: underline;
}




  