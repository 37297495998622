/* SlopeTutorial.css */
.scientific-tutorial {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

.scientific-tutorial {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .scientific-tutorial h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .scientific-tutorial p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  