.functions-tutorial {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.functions-tutorial h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.functions-tutorial h2 {
  font-size: 1.8rem;
  margin-top: 30px;
}

.functions-tutorial p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.functions-tutorial ul {
  text-align: left;
  margin-left: 40px;
}

.functions-tutorial li {
  font-size: 1.2rem;
  margin-bottom: 5px;
}
