.probability-tutorial {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.probability-tutorial {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .probability-tutorial h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .probability-tutorial p {
    font-size: 18px;
    margin-bottom: 10px;
  }